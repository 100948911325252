import EventBus from '@/util/EventBus.js'
import RoutesService from '@/services/whatsapp/routes.service'

export default {
  name: 'AddNewWaba',
  props: {
    newWaba: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    close () {
      this.newWaba.show = false
    },
    sendNotificaction () {
      this.loading = true
      RoutesService.sendNewLine()
        .then(() => {
          this.newWaba.show = false
          EventBus.$emit('showAlert', 'success', this.$t('La solicitud ha sido enviada al equipo de soporte'))
        })
        .catch(() => {
          EventBus.$emit('showAlert', 'error', this.$t('Hubo un problema, inténtelo de nuevo más tarde'))
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
